import type {
    Observer,
    Subject
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import StayDurationTypeState from '../../../modules/search/store/StayDurationTypeState';
import type { TravelPeriodUIManager } from './TravelPeriodUIManager';
import TravelPeriodStateManager from './TravelPeriodStateManager';

export default abstract class AbstractDateManager<FieldType extends HTMLElement>
    implements Observer
{
    protected readonly travelPeriodStateManager: TravelPeriodStateManager;

    protected abstract get date(): Date | null;

    protected abstract readonly handleClearEvent: () => void;

    protected abstract readonly handleClickEvent: () => void;

    abstract update(subject: Subject): void;

    abstract setActive(): void;

    abstract setInactive(): void;

    abstract get field(): FieldType | null;

    abstract set field(field: FieldType | null);

    public constructor(protected readonly travelPeriodUIManager: TravelPeriodUIManager) {
        StayDurationTypeState.getInstance().attach(this);

        this.travelPeriodStateManager = TravelPeriodStateManager.getInstance();
        this.travelPeriodStateManager.attach(this);
    }
}
