import AbstractDateFieldManager from './AbstractDateFieldManager';

export default class DepartureDateFieldManager extends AbstractDateFieldManager {
    protected get date(): Date | null {
        return this.travelPeriodStateManager.departureDate;
    }

    protected readonly handleClearEvent = (): void => {
        this.travelPeriodStateManager.clearDepartureDate();
    };

    protected readonly handleClickEvent = (): void => {
        if (!this.travelPeriodStateManager.arrivalDate) {
            this.travelPeriodStateManager.activateArrival();
            return;
        }

        this.travelPeriodStateManager.activateDeparture();
    };
}
