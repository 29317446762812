export type TravelPeriodStateManagerType = {
    arrivalDate: Date | null;
    departureDate: Date | null;
};

export enum FlexibleTravelPeriodType {
    WEEKEND = 'weekend',
    LONG_WEEKEND = 'long_weekend',
    MIDWEEK = 'midweek'
}

export enum SelectedState {
    ARRIVAL = 'arrival',
    DEPARTURE = 'departure'
}
