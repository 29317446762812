import AbstractDateFieldManager from './AbstractDateFieldManager';

export default class ArrivalDateFieldManager extends AbstractDateFieldManager {
    protected get date(): Date | null {
        return this.travelPeriodStateManager.arrivalDate;
    }

    protected readonly handleClearEvent = (): void => {
        this.travelPeriodStateManager.resetDates();
    };

    protected readonly handleClickEvent = (): void => {
        this.travelPeriodStateManager.activateArrival();
    };
}
