import AbstractDateInputManager from './AbstractDateInputManager';

export default class ArrivalDateInputManager extends AbstractDateInputManager {
    protected readonly hiddenInputSelector = '[data-name="arrivalDate"]';

    protected get date(): Date | null {
        return this.travelPeriodStateManager.arrivalDate;
    }

    protected readonly handleClearEvent = (): void => {
        this.travelPeriodStateManager.resetDates();
    };

    protected readonly handleClickEvent = (): void => {
        this.travelPeriodStateManager.activateArrival();
    };
}
