import type DatePickerCalendar from '../../../modules/calendar/webComponents/DatePickerCalendar';
import type { DatePickerCalendarSelectState } from '../../../modules/calendar/webComponents/DatePickerCalendar';

export default class CalendarManager {
    #calendar: DatePickerCalendar | null = null;

    public set calendar(calendar: DatePickerCalendar | null) {
        this.#calendar = calendar;
    }

    public setCalenderSelectState(state: DatePickerCalendarSelectState | null): void {
        if (this.#calendar === null) {
            return;
        }

        this.#calendar.selectState = state;
    }

    public setSelectedDateRange(arrivalDate: Date | null, departureDate: Date | null): void {
        if (this.#calendar === null) {
            return;
        }

        this.#calendar.selectedDateRange = {
            ...this.#calendar.selectedDateRange,
            start: arrivalDate,
            end: departureDate
        };
    }
}
