import type DateField from '@naturehouse/design-system/components/atoms/date-field/DateField';
import type { Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import {
    FORMATS,
    intlDateFormat
} from '@naturehouse/nh-essentials/lib/dates/intlDateTimeFormatter';
import StayDurationTypeState, {
    StayDurationType
} from '../../../modules/search/store/StayDurationTypeState';
import TravelPeriodStateManager from './TravelPeriodStateManager';
import AbstractDateManager from './AbstractDateManager';

export default abstract class AbstractDateFieldManager extends AbstractDateManager<DateField> {
    #field: DateField | null = null;

    get field(): DateField | null {
        return this.#field;
    }

    set field(field: DateField | null) {
        if (field) {
            field.addEventListener('clear', this.handleClearEvent);
            field.addEventListener('click', this.handleClickEvent);
        }

        this.#field = field;
    }

    public update(subject: Subject): void {
        if (this.field === null) {
            return;
        }

        if (subject instanceof TravelPeriodStateManager) {
            this.field.input.value = this.date
                ? intlDateFormat(this.date.toDateString(), FORMATS.SHORT_WITH_WEEKDAY)
                : '';
            this.field.input.dispatchEvent(new Event('change'));
        }

        if (subject instanceof StayDurationTypeState) {
            const { type } = subject;
            this.field.toggleAttribute('hidden', type === StayDurationType.FLEXIBLE);
        }
    }

    public setActive(): void {
        if (!this.field) {
            return;
        }

        this.field.setActive();
    }

    public setInactive(): void {
        if (!this.field) {
            return;
        }

        this.field.setInactive();
    }

    public isVisible(): boolean {
        if (!this.field) {
            return false;
        }

        if (typeof this.field.checkVisibility === 'function') {
            return this.field.checkVisibility();
        }

        return this.field.offsetWidth > 0 && this.field.offsetHeight > 0;
    }
}
