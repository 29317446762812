import AbstractSubject from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import { isDateValid } from '@naturehouse/nh-essentials/lib/dates/date';
import { SelectedState, type TravelPeriodStateManagerType } from './TravelPeriodStateManager.types';

export default class TravelPeriodStateManager extends AbstractSubject {
    static #instance: TravelPeriodStateManager | null = null;

    public static getInstance(): TravelPeriodStateManager {
        if (this.#instance === null) {
            this.#instance = new TravelPeriodStateManager();
        }

        return this.#instance;
    }

    public static destroyInstance(): void {
        this.#instance = null;
    }

    private constructor() {
        super();
    }

    #activeSelectedState: SelectedState = SelectedState.ARRIVAL;

    get activeSelectedState(): SelectedState {
        return this.#activeSelectedState;
    }

    #arrivalDate: Date | null = null;

    get arrivalDate(): Date | null {
        return this.#arrivalDate;
    }

    private set arrivalDate(arrivalDate: Date | null) {
        if (arrivalDate === null || !isDateValid(new Date(arrivalDate))) {
            this.#arrivalDate = null;
            return;
        }

        this.#arrivalDate = arrivalDate;
    }

    #departureDate: Date | null = null;

    get departureDate(): Date | null {
        return this.#departureDate;
    }

    private set departureDate(departureDate: Date | null) {
        if (departureDate === null || !isDateValid(new Date(departureDate))) {
            this.#departureDate = null;
            return;
        }

        this.#departureDate = departureDate;
    }

    public readonly setInitialValues = ({
        arrivalDate,
        departureDate
    }: TravelPeriodStateManagerType): void => {
        this.arrivalDate = arrivalDate;
        this.departureDate = departureDate;
    };

    public readonly activateArrival = (): void => {
        this.#activeSelectedState = SelectedState.ARRIVAL;
        this.notify();
    };

    public readonly activateDeparture = (): void => {
        this.#activeSelectedState = SelectedState.DEPARTURE;
        this.notify();
    };

    public readonly update = ({
        arrivalDate,
        departureDate
    }: TravelPeriodStateManagerType): void => {
        this.arrivalDate = arrivalDate;
        this.departureDate = departureDate;

        if (arrivalDate && !departureDate) {
            this.activateDeparture();
            return;
        }

        this.activateArrival();
    };

    public readonly clearArrivalDate = (): void => {
        this.update({ arrivalDate: null, departureDate: null });
    };

    public readonly clearDepartureDate = (): void => {
        this.update({ arrivalDate: this.arrivalDate, departureDate: null });
    };

    public readonly resetDates = (): void => {
        this.arrivalDate = null;
        this.departureDate = null;
        this.activateArrival();
    };
}
