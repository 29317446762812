import { Observer, Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import type DateField from '@naturehouse/design-system/components/atoms/date-field/DateField';
import type CalendarWeekDays from '@naturehouse/design-system/components/molecules/calendar-week-days/CalendarWeekDays';
import type DatePickerCalendar from '../../../modules/calendar/webComponents/DatePickerCalendar';
import { DatePickerCalendarSelectState } from '../../../modules/calendar/webComponents/DatePickerCalendar';
import ArrivalDateFieldManager from './ArrivalDateFieldManager';
import ArrivalDateInputManager from './ArrivalDateInputManager';
import DepartureDateFieldManager from './DepartureDateFieldManager';
import DepartureDateInputManager from './DepartureDateInputManager';
import TravelPeriodStateManager from './TravelPeriodStateManager';
import { SelectedState } from './TravelPeriodStateManager.types';
import DialogManager from './DialogManager';
import CalendarManager from './CalendarManager';
import WeekDaysManager from './WeekDaysManager';

export class TravelPeriodUIManager implements Observer {
    readonly #travelPeriodStateManager: TravelPeriodStateManager;

    readonly #arrivalDateFieldManager: ArrivalDateFieldManager;

    readonly #arrivalDateInputManager: ArrivalDateInputManager;

    readonly #calendarManager: CalendarManager;

    readonly #departureDateFieldManager: DepartureDateFieldManager;

    readonly #departureDateInputManager: DepartureDateInputManager;

    readonly #dialogManager: DialogManager;

    readonly #weekDaysManager: WeekDaysManager;

    public set arrivalDateField(arrivalDateField: DateField | null) {
        this.#arrivalDateFieldManager.field = arrivalDateField;
    }

    public set departureDateField(departureDateField: DateField | null) {
        this.#departureDateFieldManager.field = departureDateField;
    }

    public set arrivalDateInput(input: HTMLInputElement | null) {
        this.#arrivalDateInputManager.field = input;
    }

    public set departureDateInput(input: HTMLInputElement | null) {
        this.#departureDateInputManager.field = input;
    }

    public set calendar(calendar: DatePickerCalendar | null) {
        this.#calendarManager.calendar = calendar;
    }

    public set dialog(dialog: Modal | null) {
        this.#dialogManager.dialog = dialog;
    }

    public set weekDays(weekDays: CalendarWeekDays | null) {
        this.#weekDaysManager.weekDays = weekDays;
    }

    public constructor() {
        this.#travelPeriodStateManager = TravelPeriodStateManager.getInstance();
        this.#arrivalDateFieldManager = new ArrivalDateFieldManager(this);
        this.#arrivalDateInputManager = new ArrivalDateInputManager(this);
        this.#calendarManager = new CalendarManager();
        this.#departureDateFieldManager = new DepartureDateFieldManager(this);
        this.#departureDateInputManager = new DepartureDateInputManager(this);
        this.#dialogManager = new DialogManager(this);
        this.#weekDaysManager = new WeekDaysManager();
        this.#travelPeriodStateManager.attach(this);
    }

    public update(subject: Subject): void {
        if (!(subject instanceof TravelPeriodStateManager)) {
            return;
        }

        const { activeSelectedState, arrivalDate, departureDate } = subject;

        this.setCalendarDateRange(arrivalDate, departureDate);

        if (activeSelectedState === SelectedState.DEPARTURE) {
            this.deactivateArrivalFields();
            this.activateDepartureFields();
            this.setCalendarState(DatePickerCalendarSelectState.END);
            return;
        }

        this.deactivateDepartureFields();
        this.activateArrivalFields();
        this.setCalendarState(DatePickerCalendarSelectState.START);
    }

    public readonly setCalendarState = (state: DatePickerCalendarSelectState | null): void => {
        this.#calendarManager.setCalenderSelectState(state);
    };

    public readonly setCalendarDateRange = (
        arrivalDate: Date | null,
        departureDate: Date | null
    ): void => {
        this.#calendarManager.setSelectedDateRange(arrivalDate, departureDate);
    };

    public readonly activateArrivalFields = (): void => {
        this.#arrivalDateFieldManager.setActive();
        this.#arrivalDateInputManager.setActive();
    };

    public readonly deactivateArrivalFields = (): void => {
        this.#arrivalDateFieldManager.setInactive();
        this.#arrivalDateInputManager.setInactive();
    };

    public readonly activateDepartureFields = (): void => {
        this.#departureDateFieldManager.setActive();
        this.#departureDateInputManager.setActive();
    };

    public readonly deactivateDepartureFields = (): void => {
        this.#departureDateFieldManager.setInactive();
        this.#departureDateInputManager.setInactive();
    };

    public readonly openDialog = (bindToElement: HTMLElement): void => {
        this.#dialogManager.openDialog(bindToElement);
    };

    public readonly getArrivalDateValue = (): string | null =>
        this.#arrivalDateInputManager.hiddenInput?.value ?? null;

    public readonly getDepartureDateValue = (): string | null =>
        this.#departureDateInputManager.hiddenInput?.value ?? null;
}

export default new TravelPeriodUIManager();
