import type { TravelPeriodUIManager } from './TravelPeriodUIManager';

export default class DialogManager {
    #dialog: Modal | null = null;

    public set dialog(dialog: Modal | null) {
        if (dialog === null) {
            return;
        }

        dialog.addEventListener('close', this.#handleCloseEvent);
        this.#dialog = dialog;
    }

    public get dialog(): Modal | null {
        return this.#dialog;
    }

    public constructor(protected readonly travelPeriodUIManager: TravelPeriodUIManager) {
        //
    }

    public readonly openDialog = (bindToElement: HTMLElement): void => {
        if (!this.#dialog || this.#dialog.isOpen) {
            return;
        }

        this.#dialog.open({ bindToElement: bindToElement });
    };

    readonly #handleCloseEvent = (): void => {
        this.travelPeriodUIManager.deactivateArrivalFields();
        this.travelPeriodUIManager.deactivateDepartureFields();
    };
}
