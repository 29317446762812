import type { Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import {
    FORMATS,
    intlDateFormat
} from '@naturehouse/nh-essentials/lib/dates/intlDateTimeFormatter';
import TravelPeriodStateManager from './TravelPeriodStateManager';
import AbstractDateManager from './AbstractDateManager';

export default abstract class AbstractDateInputManager extends AbstractDateManager<HTMLInputElement> {
    protected abstract readonly hiddenInputSelector: string;

    #field: HTMLInputElement | null = null;

    get field(): HTMLInputElement | null {
        return this.#field;
    }

    set field(field: HTMLInputElement | null) {
        if (field) {
            field.addEventListener('clear', this.handleClearEvent);
            field.addEventListener('clear', this.#openDialog);
            field.addEventListener('click', this.#openDialog);
            field.addEventListener('click', this.handleClickEvent);
        }

        this.#field = field;
    }

    get hiddenInput(): HTMLInputElement | null {
        return document.querySelector<HTMLInputElement>(this.hiddenInputSelector);
    }

    public update(subject: Subject): void {
        if (this.field === null || this.hiddenInput === null) {
            return;
        }

        if (subject instanceof TravelPeriodStateManager) {
            this.field.value = this.date
                ? intlDateFormat(this.date.toDateString(), FORMATS.SHORT)
                : '';
            this.field.dispatchEvent(new Event('change'));

            this.hiddenInput.value = this.date ? this.date.toISOString().split('T')[0] : '';
        }
    }

    public setActive(): void {
        if (!this.field) {
            return;
        }

        this.field.focus({ preventScroll: true });
        this.field.toggleAttribute('active', true);
    }

    public setInactive(): void {
        if (!this.field) {
            return;
        }

        this.field.blur();
        this.field.removeAttribute('active');
    }

    readonly #openDialog = (): void => {
        const container = document.body.querySelector<HTMLElement>(
            '[data-role="arrival-departure-input-container"]'
        );

        if (!container) {
            return;
        }

        this.travelPeriodUIManager.openDialog(container);
    };
}
